$white: #ffffff;
$blue: #650503;
$primary: $blue;
$light: #ffe4d2;
$dark: #1a0000;
$secondary: #FFBB57;
$info: #ffb891;
$success: #559800;
$warning: #ff5800;
$danger: #ec0063;

$theme-colors: (
        "light": $light,
        "dark": $dark,
        "primary": $primary,
        "secondary": $secondary,
        "info": $info,
        "success": $success,
        "warning": $warning,
        "danger": $danger,
);
