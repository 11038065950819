@import "bootstrap/scss/bootstrap.scss";
@import "colors.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(
                  circle,
                  #e3e3e3,
                  #eaeaea,
                  #e1e1e1,
                  #eaeaea,
                  #ffffff
  );
}

a {
  text-decoration: none;
  color: $primary;
}

a:hover {
  color: $secondary;
}

h2 {
  color: $primary;
}

.headImg {
  display: block;
  padding-top: 200px;
}

.scroll-down {
  bottom: 0;
  height: 100px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1;
}

.scroll-down-text {
  cursor: pointer;
  text-decoration: none;
  font-size: 24px;
  padding-bottom: 12px;
  color: $white;
  font-weight: bold;
}

.scroll-down-bar {
  animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  background: $white;
  bottom: 0;
  display: inline-block;
  height: 65px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 2px;
}

@keyframes elasticus {
  0% {
    transform: scale(1, 0);
    transform-origin: 0% 0%;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0% 0%;
  }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 0% 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0% 100%;
  }
}

@media only screen and (min-width: 576px) {
  .pimg {
    margin-top: 48px;
    margin-right: 72px;
    box-shadow: 72px -48px #f5c9b0;
  }

  .mt-common {
    margin-top: 35px;
  }

  .mt-products {
    margin-top: 75px;
  }

  .mt-presentation {
    margin-top: 150px;
  }
}

#showroom-dropdown {
  color: $primary;
}

.card {
  padding: 0;
  box-shadow: -5px -5px -5px #cbbfbf;
}

.card-body {
  height: 9rem;
}

.card-footer {
  background-color: $gray-600 ;
}

.footer {
  padding: 12px;
  color: white;
  background-color: $primary;
}

.dropdown-item:focus {
  background-color: $primary;
}

* {
  scroll-margin-top: 100px;
}